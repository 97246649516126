.letter-square {
  /* width: 80px; */
  width: calc(30px + 6 * ((100vw - 320px) / 680) * 2);
  /* padding-top: calc(30px + 6 * ((100vw - 320px) / 680)); */
  text-align: center;
  background-color: #ccc;
  border: 4px solid #000 !important;
  /* padding: 20px; */
  font-size: calc(30px + 6 * ((100vw - 320px) / 680));
}

.letter-input {
  width: 100%;
  display: block;
  margin : 0 auto;
  border: 7px #CCC;
  text-align: center;
  font-size: 70px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
