.grid-cell {
  width: 110px;
  height: 110px;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #000 !important;
  font-size: 35px;
  /* font-size: calc(4vw + 4vh + 2vmin); */
}

.grid-cell.highlighted {
  background-color: #1e7697;
}
